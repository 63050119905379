@import "theme/shared.less";

.layout {
  display: flex;
  flex-direction: column;
  gap: 1px;
  position: relative;
  flex: 1;

  :global(.FullScreen) {
    display: flex;
    flex-direction: column;
  }

  .titleContainer {
    background-color: white;
    display: flex;
    font-family: Inter;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    font-size: 12px;
    gap: 24px;
    height: 31px;
    border-bottom: 1px solid @paddings-color;
    .header {
      display: flex;
      align-items: center;
      gap: 8px;
      margin: 0;
      .title {
        font-weight: 600;
        margin: 0;
      }
    }
  }

  .wrap {
    padding: 0 16px;
    background-color: white;
    display: flex;
    flex: 1;

    .chartStick {
      position: absolute;
      z-index: 1;
      margin: 10px;
      .big {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
      }
    }
    .inLine {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }
  }

  .chart {
    display: flex;
    flex: 1;

    :global(.ordinate) {
      stroke-width: 3px;
    }
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;