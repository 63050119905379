.layout {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1px;
  position: relative;

  :global(.FullScreen) {
    display: flex;
    flex-direction: column;
  }

  .titleContainer {
    background-color: white;
    display: flex;
    font-family: Inter;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    font-size: 12px;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      margin: 0;
      .title {
        font-weight: 600;
        margin: 0;
      }
    }
  }

  .wrap {
    padding: 0 16px;
    background-color: white;
    display: flex;
    flex: 1;

    defs rect {
      height: 1000px;
    }
  }

  .chart {
    display: flex;
    flex: 1;

    > svg g:nth-child(4) {
      display: none;
    }

    :global(.ordinate) {
      stroke-width: 3px;
    }
  }
}
.dashed > path {
  stroke-dasharray: 4 2;
}

.buttonsGroup {
  display: flex;
  gap: 12px;
  .buttonWrap {
    display: flex;
    width: 32px;
    height: 32px;
  }
  .resetButton {
    flex: 1;
  }
  .coeff {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.chartWrap {
  display: flex;
  height: 400px;
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;