@import "theme/shared.less";

.container {
  width: 430px;
  margin-top: -8px;
  background-color: #FFF;
  height: 100%;
  border-left: 1px solid @paddings-color;
  overflow: auto;

  .header {
    padding: 16px;
    border-bottom: 1px solid @paddings-color;

    .title {
      text-transform: uppercase;
    }
  }

  .info {
    display: flex;
    margin-top: 12px;
    margin-bottom: 8px;
    min-height: 72px;

    .status {
      margin-left: 8px;
      background-color: @minor-bg;
      border: 1px solid @paddings-color;
      border-radius: 4px;
      padding: 7px 8px;
    }

    .admin-button {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .approve-button {
        margin-left: 8px;
      }
    }

  }

  .body {
    padding-inline: 16px;

    .title {
      color: #175cd3;
    }

    .link-button {
      font-weight: 600;
    }

    .form {
      margin-top: 16px;
      label {
        color: #4D5761;
        font-size: 12px;
      }
      :global(.ant-form-item) {
        margin-bottom: 16px;
      }
      .blur{
        color: @borderColor;
      }
    }

    .input {
      .label {
        color: @link-color
      }
      button {
        margin-top: 16px;
      }
    }

    .card-container {
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
      color: #566481;

      .margin {
        margin-right: 8px;
      }

      .card-text {
        font-weight: 600;
        font-size: 18px;
      }
    }
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;