@import "theme/shared.less";

.infrastructureWrap {
  display: flex;
  column-gap: 8px;
  width: 100%;
  height: 100%;
  border: none !important;
}

.layout {
  background-color: @paddings-color;
  position: relative;
  height: 100%;
  width: 100%;
  border: 1px solid @paddings-color;
  border-radius: 4px;
  outline: none;

  .table,
  .map,
  .datePane {
    background-color: white;
    border-radius: 4px;
  }

  .mapNDate {
    display: flex;
    flex-direction: column;
    gap: 1px;
    height: 100%;

    .map {
      flex: 1;
      div:first-child {
        max-width: 300px;
        overflow: hidden;
      }

      svg {
        width: 100%;
        height: 100%;
        user-select: none;
      }
    }

    .cursorNone {
      cursor: none;
    }
  }
}

.selection {
  fill: #2e90fa1a;
  stroke: @colorPrimary;
}

.cursorSelection {
  cursor: crosshair;

  & > div {
    pointer-events: initial;
  }
}

.dragging {
  cursor: grabbing;
}

.selectedItem {
  cursor: default;
}

.legendMap {
  width: 233px;
  border-radius: 4px;
  border: 1px solid @table-color-head-lock;
  background: #ffffff;
  padding: 12px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  position: absolute;
  bottom: 50px;
  right: 10px;

  .legendFlex {
    display: flex;
    column-gap: 8px;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  .horizontalLine {
    width: 24px;
    height: 2px;
    background-color: @colorPrimary;
  }

  .dottedLine {
    border-top: 2px dotted @colorPrimary;
    width: 24px;
    height: 0px;
  }

  .colorBlock {
    flex: 1;
    height: 8px;
  }

  .scaleLabels {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 5px;
  }

  .actionButtons {
    display: flex;
    justify-content: space-between;
  }
}

.text {
  fill: @icon-color;
  background: #ffffffcc;
  font-size: 12px;
}

.bgText {
  fill: #ffffffcc;
}

.importPopConfirm {
  max-width: 400px;
}

.hidePipesSliderContent {
  min-width: 300px;
  max-width: 450px;
  height: 150px;
  text-align: center;
  box-sizing: border-box;

  .hidePipesSliderWrapper {
    padding: 0 0 0 5px;
    display: flex;
    justify-content: space-between;

    .hidePipesSlider {
      width: 80%;
    }

    :global(.ant-slider-mark-text) {
      text-wrap: nowrap;
    }
  }

  .setValuesBlock {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    column-gap: 5px;

    :global(.ant-input-number) {
      width: 100%;
    }

    :global(.ant-row) {
      display: flex;
      flex-wrap: nowrap;
    }

    label::after {
      content: " ";
    }
  }
}

.resetBtn {
  position: relative;
}

.haveSetting::after {
  content: "";
  position: absolute;
  top: 2px;
  right: 2px;
  width: 5px;
  height: 5px;
  background-color: red;
  border-radius: 50%;
}

.tooltipWrapper {
  width: 100%;
  min-width: 200px;

  .tooltipActionTitle {
    border-bottom: 2px solid white;
    margin-bottom: 8px;
  }

  .tooltipValues {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    column-gap: 5px;
    row-gap: 2px;
    text-wrap: nowrap;

    .tooltipValuesTitle {
      max-width: 100%;
      text-wrap: wrap;
      display: flex;
      flex-wrap: wrap;
    }
  }

  .tooltipValues > div:nth-child(2n) {
    text-align: right;
    margin-right: 10px;
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;