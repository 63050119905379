@a: rgb(255,241,184);
@b: rgb(255,251,230);
@step: 5px;

.wrap {
  background: repeating-linear-gradient(135deg, @a 0, @a @step, @b @step, @b 2 * @step);
  position: relative;

  :global(.ant-btn) {
    background: repeating-linear-gradient(135deg, @a 0, @a @step, @b @step, @b 2 * @step);
  }

  &.inline {
    display: inline-block;
  }

  &:hover:after {
    content: "Контент отображается только в отладочном контуре";
    display: block;
    position: absolute;
    right: -5px;
    bottom: 0;
    transform: translateY(100%);
    background-color: rgba(255,255,255,0.8);
    padding: 0 5px;
    font-size: 12px;
    z-index: 1000000;
    text-align: right;
  }
}

.bgHidden {
  background: none;
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;