@import "theme/shared.less";
@import "theme/table.less";

.text {
  font-size: 14px;
}

.container {
  .header {
    .header-row {
      max-height: 62px;
      box-sizing: border-box;
      padding: 8px;
      padding-inline: 16px;
      border-bottom: 1px solid @paddings-color;
    }

    .cf {
      margin-left: 50px;
    }

    .filter {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .filter-button {
        margin-left: 8px;
      }
    }
  }

  .table {
    height: 576px;
    padding: 16px;
  }

  .footer {
    height: 32px;
    padding: 16px;
    display: flex;
    flex-direction: row-reverse;
    border-top: 1px solid @paddings-color;

    .cancel-button {
      margin-right: 16px;
    }

    .input {
      width: 300px;
      margin-right: 16px;
    }

    .checkbox {
      flex-grow: 1;
      align-items: center;
    }
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;